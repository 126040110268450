/** @format */
import React, { useEffect, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import alphagif from "../images/alphagif.gif"
import c1 from "../images/c1.jpeg"
import c2 from "../images/c2.png"
import openSea from "../images/openSea.png"
import s1 from "../images/s1.png"
import s2 from "../images/s2.png"
import s3 from "../images/s3.png"
import s4 from "../images/s4.png"
import alpha_log from "../images/alpha_log.png"

import Layout from "../Components/Layout/Layout";
import Wrapper from "../Components/Banner/Banner.styled";
import alpha1 from "../images/alpha1.png"
import alpha2 from "../images/alpha1.png"
import alpha3 from "../images/alpha1.png"
import alphaman1 from "../images/alphaman1.png"
import alphagirl from "../images/alphagirl.png"
import play_button from "../images/play_button.png"
import eth from "../images/eth.png"
import binance from "../images/binance.png"
// pages start here
import './Home.css';
import AOS from "aos";
import { Icon } from "@iconify/react";
import "aos/dist/aos.css";
import OpenSea from "../Components/OpenSea/OpenSea";
import Alphatera from "../Components/Alphatera/Alphatera";

function Home() {
  AOS.init();
  const [mobileMenu, setMobileMenu] = useState(false);
  // window resize hook
  useEffect(() => {
    // window size hook for sidenav
    function checkScreenSize() {
      if (window.innerWidth > 992) {
        setMobileMenu(false);
      }
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [setMobileMenu]);

  // for mobile sidenav display
  useEffect(() => {
    const navContainer = document.querySelector(".nav-link-container");
    if (mobileMenu) {
      navContainer.style.height = 100 + "vh";
    } else {
      navContainer.style.height = 0 + "px";
    }
  }, [mobileMenu]);

  // scroll function
  useEffect(() => {
    let navigation = document.querySelector("header");
    window.addEventListener("scroll", scrollFunc);
    function scrollFunc() {
      if (window.innerWidth) {
        if (
          document.body.scrollTop > 80 ||
          document.documentElement.scrollTop > 80
        ) {
          navigation.style.background = "#2a66a8";
        } else {
          navigation.style.background = "transparent";
        }
      } else {
        navigation.style.background = "#151515";
      }
    }
  }, []);

  // scroll with offset
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  const [dropdownState, setDropdownState] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
  };
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value);
    setDropdownState(!dropdownState);
  };
  return (
    <Layout>
      <Wrapper>
        <div className="banner mt-5" id="banner">
          <div className="container">
            <div className="row bannerRow">

              <div className="col-md-8 mb-5">
                <div className="bannerContentItem">
                  <div className="banner-title">
                    <h1
                      data-aos="fade-up"
                      data-aos-delay="15"
                      data-aos-duration="1000">
                      Welcome to Princess Alphaterra & Alfat Man{" "}
                      <span className="center">
                        <span> NFT Collections</span>
                      </span>
                    </h1>
                    <p
                      data-aos="fade-up"
                      data-aos-delay="15"
                      data-aos-duration="1800">
                      {/* Alphaterracoin Project */}

                    </p>
                  </div>
                  <div className=" iframe_flex">
                    <div className="iframe_video mt-5">
                    <iframe style={{borderRadius:'10px'}} width="350" height="300" src="https://www.youtube.com/embed/EQEU2E9AsqE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true" ></iframe>
                    </div>
                  
                  <div className="video_adjust">
                    <div className="alpha_tera_card">
                      <div className="multi_blockcain">
                        <h5 >Multi blockchain Project Alphaterracoin</h5>
                        <ul className="multi_blockcain_list">
                          <li>Token Bep20 built in Binance Smart Chain</li>
                          <li>NFT erc20 built in Ethereum </li>
                          <li>NFT Smart Contract in Polygon </li>
                          <li>Marketplace of NFT in Opensea</li>
                        

                        </ul>

                      </div>


                    </div>
                  
                  </div>
                  </div>
                  
                 



                </div>


              </div>
              <div className="col-md-2">
                <div className="girl_avatar mt-5">
                  <img
                    src={alphagirl}
                    className="img-fluid man"
                    alt="switch"
                    loading="lazy"
                  />
                  <div className="girl_name"><h6 className="girl_name">Princess Alphaterra</h6></div>

                </div>
              </div>
              <div className="col-md-2">
                <div className="man_avatar mt-5">
                  <img
                    src={alphaman1}
                    className="img-fluid man"
                    alt="switch"
                    loading="lazy"
                  />
                  <div className="girl_name"><h6 className="girl_name">Alfat-Man</h6></div>
                </div>
              </div>


              <h4 className=" our_partners ">Our partners..</h4>
              <hr className="banner_line mt-3" />

              <div className="flex-container " >
                <div className="slider-container">
                  <ul className="slider">




                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s1}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">BINANCE</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s2}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">OPENSEA</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={alpha_log}
                          className="img-fluid "
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine ">Alpha Terra Coin</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s3}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">POLYGON</p>
                      </div>
                    </li>

                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s4}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine mt-3">ETHEREUM</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s1}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">BINANCE</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s2}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">OPENSEA</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={alpha_log}
                          className="img-fluid "
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine ">Alpha Terra Coin</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s3}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">POLYGON</p>
                      </div>
                    </li>

                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s4}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine mt-3">ETHEREUM</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s1}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">BINANCE</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s2}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">OPENSEA</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={alpha_log}
                          className="img-fluid "
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine ">Alpha Terra Coin</p>
                      </div>
                    </li>
                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s3}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine">POLYGON</p>
                      </div>
                    </li>

                    <li className="slider__slide">
                      <div className="slider__content">
                        <img
                          src={s4}
                          className="img-fluid " style={{ width: '30% !important' }}
                          alt="switch"
                          loading="lazy"
                        />
                      </div>
                      <div className="sliderLine">
                        <p className="sliderLine mt-3">ETHEREUM</p>
                      </div>
                    </li>







                  </ul>
                </div>
              </div>

            </div>
          </div>    {/* //container */}




          {/*           <Alphatera />

 */}
          <div className="container">
            <div className="row our_gif ">

              <div className="col-md-6">
                <div className="opensea_gif" style={{ maxWidth: '80%' }}>
                  <img
                    src={alphagif}
                    className="img-fluid opensea_gif"
                    alt="switch"
                    loading="lazy"
                  />
                </div>

              </div>

              <div className="col-md-6">
                <div className="opensea_heading">
                  <h1>Crypto Alphaterracoin Collection
                  </h1>
                </div>
                <div className="opensea_para ">
                  <p>Crypto Alphaterracoin Collection is a collection of 400 amazing NFT's. They are all available on <a style={{ color: 'orange' }} href="https://opensea.io/collection/crypto-alphaterracoin-collection" target="_blank"> Opensea. </a>   </p>
                  <p>Follow these guidelines to get yours:</p>


                  <ol className='opensea_list_item'>
                    <li className='opensea_list_item'>Visit <a style={{ color: 'orange' }} href="https://opensea.io/" target="_blank">Opensea</a> marketplace.</li>
                    <li className='opensea_list_item'>Navigate to the <a style={{ color: 'orange' }} href="https://opensea.io/collection/crypto-alphaterracoin-collection" target="_blank"> NFT </a> you'd like to purchase.</li>

                    <li className='opensea_list_item'>Tap the Buy now button.</li>
                    <li className='opensea_list_item'>Select your metamask wallet to login.</li>
                    <li className='opensea_list_item'>Review the details of your purchase and tap Purchase when ready.</li>
                    <li className='opensea_list_item'>Complete the in-wallet transaction request to confirm the purchase.</li>



                  </ol>
                </div>

                {/* <div className="girl_link">
                  <h5> Visit Opensea to see collection...</h5>
                </div>
                <ul className="comingSoon_home">


                  <li className="man_btn">
                    <a
                      className="mint-now-button home_btn"
                      href="https://opensea.io/collection/crypto-alphaterracoin-collection"
                      target="_blank"
                    >
                      Crypto Alphaterracoin Collection
                    </a>
                  </li>
        
                </ul> */}
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row girl_row ">



              <div className="col-md-6">
                <div className="opensea_heading">
                  <h1>
                    Alfaterracoin Superhero Collection
                  </h1>
                </div>

                <div className="opensea_para ">
                  <p>Crypto Alphaterracoin Collection is a collection of 20 amazing NFT's. They are all available on <a style={{ color: 'orange' }} href="https://opensea.io/collection/alfaterracoin-superhero-collection" target="_blank"> Opensea. </a>   </p>
                  <p>Follow these guidelines to get yours:</p>

                  <ol className='opensea_list_item'>
                    <li className='opensea_list_item'>Visit <a style={{ color: 'orange' }} href="https://opensea.io/" target="_blank">Opensea</a> marketplace.</li>
                    <li className='opensea_list_item'>Navigate to the <a style={{ color: 'orange' }} href="https://opensea.io/collection/alfaterracoin-superhero-collection" target="_blank"> NFT </a> you'd like to purchase.</li>

                    <li className='opensea_list_item'>Tap the Buy now button.</li>
                    <li className='opensea_list_item'>Select your metamask wallet to login.</li>
                    <li className='opensea_list_item'>Review the details of your purchase and tap Purchase when ready.</li>
                    <li className='opensea_list_item'>Complete the in-wallet transaction request to confirm the purchase.</li>



                  </ol>
                </div>
                {/* <div className="girl_link">
                  <h5> Visit Opensea to see collection...</h5>

                </div>
                <ul className="comingSoon_home">


                  <li className="man_btn">
                    <a
                      className="mint-now-button home_btn"
                      href="https://opensea.io/collection/alfaterracoin-superhero-collection"
                      target="_blank"
                    >
                      Alfaterracoin Superhero Collection
                    </a>
                  </li>

                </ul> */}
              </div>
              <div className="col-md-6">
                <div className="alphaman_1 ">
                  <img
                    src={c1}
                    className="img-fluid new_man_img"
                    alt="switch"
                    loading="lazy"
                  />
                </div>

              </div>


            </div>


          </div>

          <div className="container mt-3">
            <div className="bottom_click_btn">

              <a target="_blank" href="https://presale.alphaterracoin.com/"><button className="btn btn-primary" target="_blank" href="https://presale.alphaterracoin.com/"> Click here for Token Alphat Presales</button></a>



            </div>


          </div>


        </div>




      </Wrapper>
      {/* <OpenSea /> */}
    </Layout>
  );
}

export default Home;
