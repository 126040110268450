/** @format */

import React from "react";
import Wrapper from "./Footer.styled";
import { Icon } from "@iconify/react";
import { NavHashLink } from "react-router-hash-link";
import Logo from "../../images/alpha_logo.png";

function Footer() {
  // scroll with offset
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <Wrapper>
      <footer>

        <div className="contianer-fluid footer-container ">
          <div className="footerLogo">
            <a href="https://alphaterracoin.com/" target="_blank">

              <img src={Logo} className="img-fluid" alt="logo" />
            </a>
          </div>
          <div className="container ">
            <div className="row ">
              {/* single item  */}
              <div className="col-md-4">
             
              </div>
              <div className="col-md-4">
                <div className="footerSingleItem">

                  <p>
                    <span>&copy; Copyright 2023</span>{" "}
                    <span className="empireText">Alfaterracoin</span>
                  </p>

                </div>


              </div>
              <div className="col-md-4">

                <div className="social_icons">

                  <a href="https://twitter.com/alphaterracoin"
                  target="_blank">
                    <span className="center">
                      <span className="iconsItem">
                        <Icon icon="ant-design:twitter-outlined" />
                      </span>
                    </span>
                  </a>

                  <a href="https://www.instagram.com/alphaterratoken/"
                  target="_blank">
                    <span className="center">
                      <span className="iconsItem">
                        <Icon icon="bxl:instagram-alt" />
                      </span>
                    </span>
                  </a>
                  <a href="/#"
                  target="_blank">
                    <span className="center">
                      <span className="iconsItem">
                        <Icon icon="bxl:telegram" />
                      </span>
                    </span>
                  </a>
                </div>
                <div className="cummunity">
                  <h6>Join Our Cummunity</h6>
                </div>
              </div>
              {/* single item end here */}
            </div>
          </div>
        </div>
      </footer>
    </Wrapper>
  );
}

export default Footer;
