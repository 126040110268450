/** @format */

import styled from "styled-components";
import MintBg from "../../images/mint.png";

const Wrapper = styled.div`
  .borderFooter {
    width: 100%;
  }
  .social_icons{
    display: flex;
    align-items: end;
    justify-content: end;
    column-gap: 20px;
    transition: all 0.5s ease-in-out 0s;

  }
  .social_icons a{
    transition: all 0.5s ease-in-out 0s;
  }
  .social_icons a:hover{
    transition: all 0.5s ease-in-out 0s;
    transform: translateY(-13px) !important ;
  }
 
  .cummunity{
    display: flex;
    align-items: end;
    justify-content: end;
  }
  .row {
    justify-content: center;
  }
  footer {
    text-align: center;

    position: relative;
  }
  .footerSingleItem {
    padding-top: 20px;
  }
  .footerSingleItem p {
    color: #dddddd;
    font-size: 15px;
  }
  .SocialFooter {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 40px;
    flex-wrap: wrap;
  }
  .SocialFooter li {
    list-style: none;
  }
  .SocialFooter li a {
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    color: white;

    @media (max-width: 575.98px) {
      display: inline-block;
      margin-bottom: 20px;
    }
  }
  .shape1 {
    position: absolute;
    top: auto;
    bottom: -7px;
    width: 38%;
    left: 0;
  }
  .shape2 {
    left: auto;
    right: 0;
  }
  .footerSocial {
    display: flex;
    align-items: start;
    justify-content: end;
    column-gap: 17px;
    
  }
  .footerSocial a {
    transition: 0.5s ease-in-out;
  }
  .footerSocial a:hover {
    transform: translateY(-10px);
  }
  .footerLogo img {
  
    
    width: 12vh;
    transition: all 0.5s ease-in-out;
  }
  .footerLogo img:hover {
    transform: translateY(-10px);
  }
  .iconsItem {
    font-size: 30px;
  }
  @media (max-width: 1200px) {
    .footerSocial {
      position: relative;
      // top: 8px;
    }
  }
  @media (max-width: 992px) {
    .iconsItem {
      font-size: 19px;
    }
  }
  @media (max-width: 767.98px) {
    .col-md-8 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .social_icons{
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 20px;
    }
    .cummunity{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .footerSocial {
      position: relative;
      top: 0;
    }
    .shape1 {
      position: absolute;

      bottom: 0;
      width: 32%;

      height: 40px;
      object-fit: cover;
      border-top-right-radius: 60px;
    }
    .shape2 {
      left: auto;
      right: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 50px;
    }
  }
  @media (max-width: 575.98px) {
    .SocialFooter {
      column-gap: 5px;
      flex-wrap: wrap;
      text-align: center;
    }
    
    .footerSocial {
      position: relative;
      top: 9vh;
    }
    .footerSocial a img {
      width: 10px;
    }
    .footerSocial {
      column-gap: 5px;
    }
  }
  .center span {
    color: #f3d74c;
    background-size: cover;
    background-image: url(${MintBg});
    -webkit-background-clip: text;
    animation: background-text-animation 15s linear infinite;
  }
  @keyframes background-text-animation {
    0% {
      background-position: left 0px top 50%;
    }
    50% {
      background-position: left 1500px top 50%;
    }
    100% {
      background-position: left 0px top 50%;
    }
  }
  .navMenuLink {
    transition: all 0.5s ease-in-out;
    position: relative;
  }

  .navMenuLink::before,
  .navMenuLink::after {
    position: absolute;
    content: "";
    width: 0%;
    background-color: #c0941d;
    top: -5px;
    height: 2px;
    transition: width 0.5s ease-in-out;
  }
  .navMenuLink::after {
    top: auto;
    bottom: -5px;
    left: 0;
  }
  .navMenuLink:hover::before {
    width: 100%;
  }
  .navMenuLink:hover::after {
    width: 100%;
  }
  .navMenuLink:hover {
    transform: translateY(-10px);
    color: #c0941d;
  }
  .empireText {
    color: rgba(255, 0, 0, 0.1);
    background-size: cover;
    background-image: url(${MintBg});
    -webkit-background-clip: text;
    animation: background-text-animation 15s linear infinite;
  }

  @keyframes background-text-animation {
    0% {
      background-position: left 0px top 50%;
    }
    50% {
      background-position: left 1500px top 50%;
    }
    100% {
      background-position: left 0px top 50%;
    }
  }
  .footer-container{
    background-color: #2a66a8;
  }
`;

export default Wrapper;
