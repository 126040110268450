/** @format */

import styled from "styled-components";
import MintBg from "../../images/mint.png";
const Wrapper = styled.nav`

  padding-top: 120px;
  .banner {
    position: relative;
    padding: 20px 0px;
  }
  .golden-text {
  }
  .bannerContentItem {
    margin-top:30px;
  }
  .banner-title {
  }
  .banner-title h1 {
    font-size: 54px;
  }
  .banner-title h1 span img {
    position: relative;
    top: 14px;
  }
  .banner-title p {
    font-size: 24px;
    color: #ffffff;
    margin: 20px 0px 0px 0px;
  }
  .banner-button-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mint-button {
    display: flex;
    align-items: center;
    column-gap: 50px;
  }
  .mint-button a {
  }
  .coming-button {
  }
  .coming-button a img {
    width: 22vh;
  }
  .arrow {
  }
  .arrow img {
  }

  .mint-now-button {
   
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 700;
    color: white;
    text-decoration: none;
  }
  .card-item {
    display: flex;
    column-gap: 20px;
    justify-content: space-between;
    padding-left:50px;
  }
  .cards-first {
    text-align: center;

    @media (max-width: 767.98px) {
      text-align: center;
      .cardImg{
        display:none;
      }
    }
    
    
  }
  .cards-first img {
    max-width:60%;
    cursor: pointer;
    border-radius:20px;
    transition: 0.5s ease-in-out;
   
    @media (max-width: 1192px) {
      width: 70%;
    }
    @media (max-width: 767.98px) {
      width: 50%;
      // margin-left:50px;
    }
    @media (max-width: 575.98px) {
      // width: 80%;
      
    margin-top: 15%;

    }
    :hover {
      transform: translateY(-20px);
    }
  }

  .boxShadow {
    -webkit-box-shadow: 28px 10px 199px 141px rgb(161 118 10 / 58%);
    -moz-box-shadow: 28px 10px 199px 141px rgba(161, 118, 10, 0.58);
    width: 20%;
    height: 31%;

    position: absolute;
    top: 34%;
    filter: blur(84px);
    left: auto;
    right: 0;
    z-index: -22;
  }

  @media (max-width: 575.98px) {
    .bannerContentItem {
      padding-right: 0px;
      margin-top: 0px;
    }
    // .bannerRow {
    //   flex-direction: column-reverse !important;
    // }
    .col-md-7,
    .col-md-5 {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    .banner-title h1 {
      font-size: 25px;
    }
    .banner-title h1 span img {
      width: 56%;
      top: 3px;
    }
    .arrow img {
      width: 70%;
    }
    .banner-title p {
      font-size: 15px;
      line-height: 29px;
      margin-bottom: 20px;
    }
    .mint-button {
      column-gap: 20px;
      flex: 0 0 100%;
    }
    .banner-button-item {
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
    .arrow {
      flex: 0 0 100%;
      text-align: center;
    }
    .mint-now-button {
      font-size: 14px;
    }
    .boxShadow {
      width: 20%;
      height: 21%;

      top: 0%;
    }
  }

  @media (min-width: 576px) and (max-width: 768.98px) {
    .bannerContentItem {
      padding-right: 60px;
      margin-top: 120px;
      display: flex;
      justify-content: center;
      align-items: center;


    }
   
    .col-md-7,
    .col-md-5 {
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
    }
    .banner-title h1 {
      font-size: 32px;
    }
    .banner-title h1 span img {
      width: 52%;
      top: 8px;
    }
    .arrow img {
      width: 100%;
    }
    .banner-title p {
      font-size: 16px;
      line-height: 29px;
    }
    .mint-button {
      column-gap: 20px;
      flex: 0 0 70%;
    }
    .mint-now-button {
      font-size: 16px;
      margin-left: 2% !important;
    }
    .boxShadow {
      width: 20%;
      height: 21%;

      top: 0%;
    }
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    .banner-title h1 {
      font-size: 29px;
    }
    .banner-title h1 span img {
      width: 58%;
      top: 8px;
    }
    .arrow img {
      width: 100%;
    }
    .banner-title p {
      font-size: 16px;
      line-height: 29px;
    }
    .mint-button {
      column-gap: 20px;
      flex: 0 0 70%;
    }
    .mint-now-button {
      font-size: 14px;
      margin-left: 2% !important;
    }
    .boxShadow {
      width: 11%;
      height: 13%;
      position: absolute;
      top: 28%;
    }
  }

  @media (min-width: 992px) and (max-width: 1199.98px) {
    .mint-button {
      column-gap: 30px;
      flex: 0 0 70%;
    }
    .banner-title h1 {
      font-size: 45px;
    }
    .banner-title h1 span img {
      width: 58%;
    }
    .arrow img {
      width: 90%;
    }
    .banner-title p {
      font-size: 19px;
    }
    .mint-now-button {
      font-size: 16px;
      margin-left: 2% !important;
    }
  }
  .center {
  }
  .center span {
    color: rgba(255, 0, 0, 0.1);
    background-size: cover;
    background-image: url(${MintBg});
    -webkit-background-clip: text;
    animation: background-text-animation 15s linear infinite;
  }
  @keyframes background-text-animation {
    0% {
      background-position: left 0px top 50%;
    }
    50% {
      background-position: left 1500px top 50%;
    }
    100% {
      background-position: left 0px top 50%;
    }
  }
  .Coming {
    font-size: 24px;
    @media (max-width: 992px) {
      font-size: 17px;
    }
  }
  @media (max-width: 992px) {
    .banner {
      padding: 60px 0px;
    }
  }




  .slider {
    --slider-inner-width: 2500px;
    --slider-speed: 30s;
  }
  
  @keyframes scroll {
    0% { transform: translateX(-5%) ; }
    100% { transform: translateX(calc((0px - var(--slider-inner-width)) - 5%))}
  }
  .slider {
    -webkit-transform: translate3d(0, 0, 0);
    animation: scroll linear infinite var(--slider-speed);
    width: calc(2 * var(--slider-inner-width));
    transition: animation-play-state ease 0.3s;
    
  }
  
  
  
  .slider-container {
    
    height: 100px;
    overflow:hidden;
    position: relative;
    width: 100%;
    .slider {
      display: flex;
      align-items: center;
      list-style: none;
      margin: 0;
    }
    
    .slider__slide {
      height: 100px;
      flex-grow: 1;
     
      display: flex;
      align-items: center;
      justify-content: center;
     
      border-right: solid #ffb945  1px;
  
      
      &:hover {
       
        cursor: pointer;
      }
     
    }
  }
  .sm-icons {
 
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  
  
  }
  .sm-icons a img {
      animation: myAnim 2s ease 0s infinite normal forwards;
  }
  .sm-icons img {
    animation: 2s ease 0s infinite normal forwards running myAnim;
  }
`;
export default Wrapper;
