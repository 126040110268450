/** @format */

import styled from 'styled-components'
import MintBg from '../../images/mint.png'
const Wrapper = styled.nav`
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 20px 0px;
  align-items: center;
  box-shadow: -5px -3px 35px 0px rgba(31, 10, 171, 0.58);

  // -webkit-box-shadow: -5px -3px 35px 0px rgba(250, 255, 5, 0.58);
  // -moz-box-shadow: -5px -3px 35px 0px rgba(250, 255, 5, 0.58);
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 992px) {
    position: relative;
    justify-content: space-between;
    padding: 0 20px;
  }
  @media (max-width: 600px) {
    padding: 20px 10px;
    min-height: 80px;
  }
  .nav-logo {
    display: flex;
    justify-content: center;
    transition: 0.5s ease-in-out;
    img {
      width: 14vh;

      @media (max-width: 575.99px) {
        width: 10vh;
      }
    }
    ${'' /* :hover {
      transform: scale(1.1);
    } */}
    p {
      font-size: 32px;
      font-weight: 300;
      font-family: 'Poppins', sans-serif;
      cursor: pointer;

      @media (max-width: 1199.98px) {
        font-size: 24px;
      }
    }
  }
  .nav-link-container {
    display: flex;
    justify-content: space-between;
    flex: 0 0 75%;
    @media (max-width: 1250px) {
      flex: 0 0 80%;
    }
    @media (max-width: 1192px) {
      flex: 0 0 86%;
    }
    @media (max-width: 992px) {
      position: absolute;
      left: 0%;
      top: 0%;
      width: 100%;
      height: 0;
      overflow: hidden;
      background: #000000f2;
      transition: 0.5s ease-out;
      display: block;
      flex: 0 0 100%;
      text-align: center;
    }
    ul {
      list-style: none;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: 992px) {
        flex-direction: column;

        width: 100%;
        padding-bottom: 20px;
      }
      li {
        margin: 0 0px;
        @media (max-width: 1199.98px) {
          margin: 0 0px;
          padding: 10px 0px;
        }
        a {
          margin: 0px 15px;
          font-size: 20px;
          font-weight: 500;
          position: relative;

          @media (max-width: 1199px) {
            margin: 0px 8px;
            font-size: 18px;
          }
          @media (max-width: 992px) {
            display: inline-block;
          }
        }
      }
      @media (max-width: 992px) {
        width: 100%;
        position: relative;
        top: 8%;
        height: 30%;
        padding: 20px 0px;
        display: block;
      }
      a {
        text-decoration: none;
        color: #ffffff;
        display: block;
        -webkit-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        font-weight: 500;
        font-size: 1rem;

        @media (max-width: 992px) {
          text-align: center;
        }
      }
    }
    .nav-link-last-item {
      @media (max-width: 992px) {
        margin: 0;
      }
    }
    .social-media-icon {
      display: flex;
      margin-top: 12px;
      @media (max-width: 992px) {
        justify-content: center;
      }
      a {
        margin: 0 10px;
        font-size: 30px;
        :hover {
          transform: translateY(-10px);
          color: #24ae4f;
        }
        @media (max-width: 1199px) {
          margin: 0px 7px;
          font-size: 30px;
        }
      }
    }
    .nav-button {
      @media (max-width: 992px) {
        text-align: center;
        max-width: 220px;
        margin: 0 auto;
      }
      a {
        background: linear-gradient(
          90deg,
          #25b24c 0%,
          #229c5e 50%,
          #1f8672 101.5%
        );
        color: white;
        font-weight: 600;
        padding: 12px 45px;
        border-radius: 38px;
        box-shadow: 0px 4px 43pxrgba (0, 0, 0, 0.25);
        -webkit-transition: 0.5s ease-in-out;
        -webkit-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        padding: 0 2.25rem;
        line-height: 56px;
        font-size: 1.2rem;
        font-weight: 500 !important;
        min-width: 190px;
        text-align: center;
        margin: 0;
        margin-left: 15px;
        display: none;
        :hover {
          background-color: transparent;
          color: #151515;
        }
      }
    }
  }

  .mobile-menu {
    display: none;
    @media (max-width: 992px) {
      display: flex;
      button {
        border: none;
        color: #ffffff;
        font-size: 30px;
        background: transparent;
        z-index: 587;
      }
    }
  }
  .comingSoon {
    @media (max-width: 992px) {
      top: 5% !important;
    }
  }
  .comingSoon li {
  }
  .comingSoon li a {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      justify-content: center;
    }
  }
  .comingSoon li a span {
    margin: 0 8px;
  }
  .comingSoon li a span img {
    height: 13px;
  }
  .mint-now-button {
    background-image: -webkit-linear-gradient(left, #059cf9 0%, #ffb945 100%);
    background-image: linear-gradient(to right, #059cf9 0%, #ffb945 100%);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 10px 20px;
    @media (max-width: 992px) {
      width: 20%;
      display: block;
      text-align: center;
      margin: 0 auto !important;
    }
    @media (max-width: 767px) {
      width: 23%;
    }
    @media (max-width: 575.98px) {
      width: 43%;
    }
  }
  .mint-now-button:hover {
    color: white !important;
  }
  .navMenuLink {
    transition: all 0.5s ease-in-out;
  }
  .navMenuLink::before,
  .navMenuLink::after {
    position: absolute;
    content: '';
    width: 0%;
    background-color: #c0941d;
    top: -5px;
    height: 2px;
    transition: width 0.5s ease-in-out;
  }
  .navMenuLink::after {
    top: auto;
    bottom: -5px;
    left: 0;
  }
  .navMenuLink:hover::before {
    width: 100%;
  }
  .navMenuLink:hover::after {
    width: 100%;
  }
  .navMenuLink:hover {
    transform: translateY(-10px);
    color: #c0941d;
  }
  .center {
  }
  .dropbtn {
    color: rgba(255, 0, 0, 0.1);
    background-size: cover;
    background-image: -webkit-linear-gradient(left, #059cf9 0%, #ffb945 100%);
    background-image: linear-gradient(to right, #059cf9 0%, #ffb945 100%);
    -webkit-background-clip: text;
    animation: background-text-animation 15s linear infinite;
  }
  @keyframes background-text-animation {
    0% {
      background-position: left 0px top 50%;
    }
    50% {
      background-position: left 1500px top 50%;
    }
    100% {
      background-position: left 0px top 50%;
    }
  }
  .dropbtn {
    transition: all 0.5s ease-in-out;
    border: 0;
    outline: 0;
    font-size: 20px;
    cursor: pointer;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    transition: all 0.5s ease-in-out;
    @media (max-width: 992px) {
      display: inline-block !important;
      width: 50%;
    }
    @media (max-width: 575.98px) {
      width: 100%;
    }
  }

  .dropdown-content {
    display: none;
    // background: black;
    position: absolute;
    z-index: 1;
    box-shadow: 0px 8px 16pxrgba (0, 0, 0, 0.2);
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    left: auto;
    right: -1%;
    @media (max-width: 992px) {
      width: 50%;

      left: 27%;
      bottom: 0vh;
    }
  }

  .dropdown-content a {
    display: block;
    color: #000;
    text-decoration: none;
    font-size: 13px !important;
    transition: all 0.5s ease-in-out;
  }

  .dropdown-content a:hover {
    background: #f3d74c;
    color: black;
  }

  .dropdown:hover .dropdown-content {
    display: block;
    width: 110%;
    @media (max-width: 992px) {
      width: 50%;
    }
    @media (max-width: 767.98px) {
      width: 70%;
    }
    @media (max-width: 575.98px) {
      width: 80%;
      left: 11%;
    }
  }
  .dropdown-content a {
    margin: 0 !important;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    column-gap: 5px;
    @media (max-width: 992px) {
      display: block !important;
      text-align: left !important;
    }
  }
  .top_btn_link{
    font-size: 14px !important;
    padding-right: 26px !important;
  }
  .navbar_btn_first{
    margin-right:50px !important;
  }
  @media (max-width: 992px) {
    .navbar_btn_first{
      margin-right:0px !important;
    }
  }
`
export default Wrapper
