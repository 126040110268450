/** @format */

import React, { useEffect, useState } from "react";
import Wrapper from "./Navbar.styled";
import { Icon } from "@iconify/react";
import { NavHashLink } from "react-router-hash-link";
import Logo from "../../images/alpha_logo.png";
function Navbar() {
  const [mobileMenu, setMobileMenu] = useState(false);
  // window resize hook
  useEffect(() => {
    // window size hook for sidenav
    function checkScreenSize() {
      if (window.innerWidth > 992) {
        setMobileMenu(false);
      }
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, [setMobileMenu]);

  // for mobile sidenav display
  useEffect(() => {
    const navContainer = document.querySelector(".nav-link-container");
    if (mobileMenu) {
      navContainer.style.height = 100 + "vh";
    } else {
      navContainer.style.height = 0 + "px";
    }
  }, [mobileMenu]);

  // scroll function
  useEffect(() => {
    let navigation = document.querySelector("header");
    window.addEventListener("scroll", scrollFunc);
    function scrollFunc() {
      if (window.innerWidth) {
        if (
          document.body.scrollTop > 80 ||
          document.documentElement.scrollTop > 80
        ) {
          navigation.style.background = "##2a66a8";
        } else {
          navigation.style.background = "transparent";
        }
      } else {
        navigation.style.background = "#2a66a8";
      }
    }
  }, []);

  // scroll with offset
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -120;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  const [dropdownState, setDropdownState] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");

  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
  };
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value);
    setDropdownState(!dropdownState);
  };
  return (
    <Wrapper>
      <div className="container">
        <div className="nav-logo">
          <a href="https://alphaterracoin.com" target="_blank">
            {" "}
            <img src={Logo} className="img-fluid golden_logo" alt="logo" />
          </a>
        </div>
        <div className="nav-link-container ">
          <ul>
            
      

            <li className="nav-button">
              <li>
                <a
                  to="/#banner"
                  scroll={(el) => scrollWithOffset(el)}
                  onClick={() => {
                    setMobileMenu(false);
                  }}>
               
                </a>
              </li>
            </li>
          </ul>
          <ul className="comingSoon">
           
            <li>
              <a
                className="mint-now-button navbar_btn_first"
               href="https://opensea.io/collection/alfaterracoin-superhero-collection"
               target="_blank"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMobileMenu(false);
                }}>
                               Alfaterracoin Superhero Collection

              </a>
              <a className="top_btn_link" href="https://opensea.io/collection/alfaterracoin-superhero-collection" target="_blank"> https://opensea.io/collection/alfaterracoin-superhero-collection</a>

            </li>
            
            <li>
              <a

                className="mint-now-button"
                href=" https://opensea.io/collection/crypto-alphaterracoin-collection"
                target="_blank"
                scroll={(el) => scrollWithOffset(el)}
                onClick={() => {
                  setMobileMenu(false);
                }}>
                                                        Crypto Alphaterracoin Collection
              </a>
              <a className="top_btn_link" href="https://opensea.io/collection/crypto-alphaterracoin-collection" target="_blank"> https://opensea.io/collection/crypto-alphaterracoin-collection</a>

            </li>
          </ul>
        </div>
        <div className="mobile-menu">
          <button
            onClick={() => {
              setMobileMenu(!mobileMenu);
            }}>
            {mobileMenu ? (
              <Icon icon="akar-icons:cross" />
            ) : (
              <Icon icon="uim:bars" />
            )}
          </button>
        </div>
      </div>
    </Wrapper>
  );
}

export default Navbar;
